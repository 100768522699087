/* Bg-Color */
.hero-section {
   background-image: linear-gradient(#F3E9D2, rgba(0, 0, 0, 0.64));
   max-width: 80%;
   margin: 20px auto;
   border-radius: 30px;
   padding: 2%;
   color: var(--hero-color);
   height: 80vh;
   padding: 20px;
   width: 100%;
}

/* Text Boldness */
.hero-section .hero-title {
 font-weight: 600;
}

/* Block Styles */
.icon-gallery {
   display: flex;
   justify-content: space-around;
   flex-wrap: wrap;
}

/* Icon Styles */
.icon-gallery__icon{
    width: 40px;
    height: auto;
    margin: 10px;
    transition: transform 0.3s ease;
}

/* Border Radius Comptia */
.icon-gallery__icon:nth-of-type(11) {
    border-radius: 5px;
}

/* Hover Effect on Icons */
.icon-gallery__icon:hover {
    box-shadow: 0 0 15px rgba(105, 100, 99, 0.5);
}



.profile-picture{
    width: 100%;
    max-width: 100%
}

/* Small Devices */
@media (max-width: 768px) {

.hero-section{
    height: auto;
}

/* Adjust layout for medium and larger screens */
.icon-gallery__icon{
    width: 30px;
    height: 30px;
}

.profile-picture {
    display: none;
}

}

/* Medium Screens */
@media (min-width: 768px) and (max-width: 1024px) {
    .profile-picture{
        width: 60%;
    }  

}


/* Laptop Screens */
@media (min-width: 1024px) {
    
}