

/* Title styles */

#title-bold {
    font-weight: 400;
    transition: transform 0.2s ease-in-out;
}


#title-bold:hover{
transform: translateY(-2px);
}



.custom-container {
    position: relative;
    margin: 10px;
}


.gradient {
    height: 100%;
    width: 100%;
    background-color: black;
    position: absolute;
    opacity: 0.2;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 2px;
}


.card-img {
    border-radius: 2px;
    object-fit: cover;
}

/* remove default anchor styles */
a {
    text-decoration: none;
    color: var(--text-color);
}

/* Hover effect */
a:hover {
    color: var(--hero-color);
}


/* Skills Container Styles */
.skills-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1%;
    width: 100%;
}


/* Chevron styles */

.chevron {
    transform: rotate(45deg);
    margin-left: 15px;
}

