.custom-form-style {
    background-color: var(--background-color);
    border-radius: 50px;
    padding: 50px;
}

/* Removes custom selection color and Updates new */

.form-control:focus {
    box-shadow: none;
    border: 2px solid var(--hero-color);
}


.btn-container {
    max-width: 300px;
}


.custom-btn-style  {
    width: 100%;
    background-color: var(--hero-color);
    color: var(--background-color);
    border-radius: 15px;
    max-width: 300px;
}

/* Small Screens */
 @media (max-width: 768px) {
  

    .contact-half {
        margin-top: 30px;
    }
} 