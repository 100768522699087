/* navbar layout */
.custom-navbar {
   display: grid;
   grid-template-columns: 1fr auto;
   max-width: 80%;
   margin: 0px auto;
   border-radius: 40px;
}




/* Customized Background Color */
 .custom-navbar-bg {
   padding-top: 10px;
   background-color: var(--navbar-bg);
   border: 1px solid #d3cfceb6; 
 }



 /* Center Nav Items */
 .custom-alignment {
    justify-content: center;
 }


/* Black Color */
.custom-alignment > .custom-nav-color {
   color: var(--text-color);
}


 /* Small Screen Devices */
@media (max-width: 768px) {
   .custom-alignment {
      justify-content: center;
      align-items: center;
   }
}