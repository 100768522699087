

/* .about-container {
    background-image: linear-gradient(#F3E9D2, rgba(0, 0, 0, 0.64));
} */
.about-container {
    margin: 4% 0px 4% 0px;
    display: flex;
}

.about-container .about__title {
    font-weight: 600 !important;
}

