.Card-Style {
    transition: transform 0.3s ease;
}

.Card-Style:hover {
    transform: scale(0.95);
  }

.read-more-link {
   display: inline-block;
   padding: 8px 16px;
   background-color: var(--hero-color);
   color: var(--background-color);
   text-decoration: none;
   border: none;
   border-radius: 4px;
   cursor: pointer;
   transition: background-color 0.3s ease;
}

.read-more-link:hover {
    background-color: var(--background-color);
    color: var(--text-color);
    border: 0.3px solid var(--text-color);
}