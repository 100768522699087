.project-container{
  margin: 4% 0px 4% 0px; /* 10px top, 20px right, 30px bottom, 40px left */
}

.experience {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.experience h1 {
font-weight: 600;
}


.experience__download {
    display: flex;
    align-items: center;
}

.experience__download > div {
    margin-right: 10px; 
}

.experience__download .resume-text {
  margin-right: 10px; 
}